body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: 200px; /* Set the size of the background image */
  background-position: center;
  background: linear-gradient(to bottom, #2e2e2e, #1c1c1c);
  color: white;
  font-family: "courier", cursive;
  background-repeat: repeat;
}

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.HeroBanner {
  height: 27vh;
  font-family: "courier", cursive;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60pt;
  color: white;
}

.Announcement {
  height: 90px;
  font-family: "courier", cursive;
  background-size: cover;
  background-position: center;
  background-color: #fcfc03;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35pt;
  color: black;
}

.AnnouncementMobile {
  height: 90px;
  font-family: "courier", cursive;
  background-size: cover;
  background-position: center;
  background-color: #fcfc03;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15pt;
  color: black;
  align-content: center;
}

.Carousel {
  height: 60px;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.85);
}

.CarouselItem img {
  padding-right: 50px;
  height: 55px;
  object-fit: scale-down; /* Adjust the value as needed */
}

.SupportedChainItem img {
  padding-right: 10px;
  padding-left: 10px;
  height: 45px;
  object-fit: scale-down; /* Adjust the value as needed */
}

.Content {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  height: calc(65vh - 60px); /* Adjust the height calculation as needed */
}

.MobileSupportedChains {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  border: none;
  margin-bottom: 0.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  height: 90%;
}

.LeftSide {
  margin-top: 1rem;
  margin-left: 4rem;
  display: grid;
  grid-template-rows: auto 1fr;
  border: 0.1rem solid rgba(255, 255, 255, 0.75); /* Adjust the alpha value as needed */
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.75); /* Adjust the alpha value as needed */
  height: calc(100% - 20px); /* Adjust the height calculation as needed */
}

.RightSide {
  margin-top: 1rem;
  margin-right: 4rem;
  display: grid;
  grid-template-rows: auto 1fr;
  border: 0.1rem solid rgba(255, 255, 255, 0.75); /* Adjust the alpha value as needed */
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.75); /* Adjust the alpha value as needed */
  height: calc(100% - 20px); /* Adjust the height calculation as needed */
}

.TabList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}

.TabList .react-tabs__tab {
  flex-grow: 1;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.TabPanel {
  margin-top: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
  border: none;
}

.TabPanelForSupportedChains {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "courier", cursive;
}

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: "courier", cursive;
}

label {
  margin-right: 10px;
  white-space: nowrap;
  width: 190px; /* Adjust the width as needed */
}

select {
  width: 150px; /* Fill the remaining space in the form row */
  height: 25px;
}

.Form input,
.Form button {
  margin-bottom: 0.5rem;
  width: 100%;
  height: 100%;
  font-family: "courier", cursive;
  font-size: 16pt;
}

input[type="text"] {
  margin-bottom: 10px;
  padding: 5px;
  width: 200px;
}

.Title {
  font-family: "courier", cursive;
  font-size: 18pt;
  top: 12px;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.Step {
  font-family: "courier", cursive;
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: -0.64rem;
}

.bulleted-text {
  list-style-type: disc;
  margin-top: 0px;
  margin-bottom: 0px;
}

.centered-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.centered-icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  height: 30px;
}

.centered-button {
  margin-top: 0rem; /* Adjust the margin as needed */
  width: 140px;
  height: 30px;
  border-radius: 15px;
  font-family: "courier", cursive;
  font-size: 12pt;
  cursor: "hand";
  border: none;
  outline: none;
  transition: "background-color 0.3s ease";
}

.capsule-button {
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  width: 140px;
  font-family: "courier", cursive;
  font-size: large;
  cursor: "hand";
  border: none;
  outline: none;
  transition: "background-color 0.3s ease";
}

.arrscan-button {
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  font-family: "courier", cursive;
  font-size: large;
  cursor: "hand";
  border: none;
  outline: none;
  transition: "background-color 0.3s ease";
}

.leftaligned-button {
  margin-top: 0rem; /* Adjust the margin as needed */
  margin-left: 12.5rem;
  margin-bottom: 0rem;
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.pending-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.gif {
  width: 30px; /* Adjust the width as needed */
  height: auto;
  margin-right: 10px; /* Adjust the spacing between GIFs and text */
  margin-left: 10px; /* Adjust the spacing between GIFs and text */
}

.Heading {
  font-size: x-large;
  font-family: "courier", cursive;
  position: relative;
  z-index: 1; /* Ensure the heading appears above other elements */
  text-align: center;
  margin: -40px auto 0; /* Adjust the margin as needed to position the heading */
}

.error-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  border-radius: 4px;
}

.error-modal h2 {
  margin-top: 0;
}

.error-modal button {
  background-color: white;
  color: black;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 0 auto; /* Add this line to center the button horizontally */
  display: block; /* Add this line to make the button a block-level element */
}

.tab-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-top: 0rem;
  border-radius: 1rem 1rem 0rem 0rem;
}

.custom-tab {
  font-family: "courier", cursive;
  font-size: 14pt;
  flex: 1;
  padding: 10px;
  background-color: rgb(255, 255, 255, 0.75);
  text-align: center;
  cursor: pointer;
  background-color: black;
  color: white;
  border-radius: 1rem 1rem 0rem 0rem;
}

.custom-tab.react-tabs__tab--selected {
  background-color: #ffffff; /* Replace with your desired color */
  color: black;
  font-weight: bolder;
}

.custom-tab:focus {
  outline: none;
}

.status-boxes {
  display: flex; /* Use flexbox to arrange containers horizontally */
}

.status-boxes-mobile {
  font-family: "courier", cursive;
  font-size: 9pt;
  color: white;
  display: flex;
  flex-direction: column; /* Change the direction to vertical (column) */
}

.chain-status-container {
  width: 100%; /* Set the container to occupy the full width of its parent */
  display: flex;
  flex-wrap: wrap; /* Allow the child elements to wrap to a new line when required */
  justify-content: center; /* Align the child elements to start from the left */
  align-items: center;
  color: white;
  gap: 12px;
}

.chain-status-container-mobile {
  width: 100%; /* Set the container to occupy the full width of its parent */
  display: flex;
  justify-content: center; /* Align the child elements to start from the left */
  align-items: center;
  color: white;
  gap: 3px;
}

.status-bar {
  display: flex;
  margin-top: 10px;
  border: 1px solid rgb(81, 81, 81);
  margin: 5px 5px;
  border-radius: 8px;
  background-color: rgb(81, 81, 81);
  margin-bottom: 0px;
}

.image-container {
  margin-left: 0px;
  height: 40px;
  width: 45px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-container-mobile {
  margin-left: 0px;
  height: 30px;
  width: 36px;
}

.image-container-mobile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.status-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "courier", cursive;
  font-size: 9pt;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  align-self: stretch; /* Take up full height of parent */
}

.status-image-container img {
  width: 100px; /* Set the width as needed */
  margin-left: 10px; /* Add margin between text and image */
  border-radius: 8px;
}

.container {
  width: 33.33%;
  display: flex;
  align-items: center;
  border: 1px solid rgb(81, 81, 81);
  padding: 10px;
  margin: 3px 3px;
  border-radius: 8px; /* Add this line to round the corners */
  background-color: black;
}

.container-mobile {
  display: flex;
  align-items: center;
  border: 1px solid rgb(81, 81, 81);
  padding: 5px;
  margin: 1px 1px;
  border-radius: 8px; /* Add this line to round the corners */
  background-color: black;
}

/* CopyToClipboardFromAbbreviated.css */
.copy-to-clipboard {
  display: flex;
  align-items: center;
  color: rgb(198, 120, 221);
  font-weight: 600;
  margin-left: 10px;
}

.copy-to-clipboard button {
  border: none;
  background: none;
  cursor: pointer;
  color: white; /* Set the icon color to white */
  padding: 0;
  margin-left: 8px;
}

.copy-to-clipboard button:focus {
  outline: none;
}

.content-wrapper {
  display: flex;
  align-items: left;
  flex-wrap: wrap; /* Allow content to wrap to new lines */
  margin-bottom: 8px;
}

/* Adjust the button styling as needed */

/* Media Query for Small Screens */
@media (max-width: 1000px) {
  .Content {
    grid-template-columns: 1fr; /* Stack columns on top of each other */
    gap: 0px;
  }

  .RightSide {
    margin-left: 0.2rem; /* Adjust margin for small screens */
    margin-right: 0.2rem; /* Adjust margin for small screens */
    height: 480px;
    margin-bottom: 0.2rem;
    order: 0;
  }

  .LeftSide {
    margin-left: 0.2rem; /* Adjust margin for small screens */
    margin-right: 0.2rem; /* Adjust margin for small screens */
    margin-bottom: 0.2rem;
    order: 1;
  }

  .TabPanel {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border: none;
  }

  .custom-tab {
    padding-right: 5px; /* Adjust the right padding as needed */
    padding-left: 5px; /* Adjust the right padding as needed */
  }

  .Carousel {
    display: none;
  }

  .MobileSupportedChains {
    display: block;
  }

  .Announcement {
    display: none;
  }

  .AnnouncementMobile {
    display: inline-flex;
  }

  .Heading {
    display: none;
  }
}

@media (min-width: 1001px) {
  .Carousel {
    display: block;
  }
  .MobileSupportedChains {
    display: none;
  }
  .Announcement {
    display: inline-flex;
  }
  .AnnouncementMobile {
    display: none;
  }
  .Heading {
    display: block;
  }
}

@font-face {
  font-family: "courier", cursive;
  src: url("./fonts/Freebooter.ttf") format("truetype");
  /* Add more src lines for other font file formats if necessary */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "freebooterShadow";
  src: url("./fonts/FreebooterShadow.ttf") format("truetype");
  /* Add more src lines for other font file formats if necessary */
  font-weight: normal;
  font-style: normal;
}

/* Hide MobileComponent by default for desktop devices */
.mobile {
  display: none;
}

/* Hide DesktopComponent by default for mobile devices */
.desktop {
  display: block;
}

/* Media query for mobile devices with a max-width of 600px */
@media (max-width: 600px) {
  /* Show MobileComponent and hide DesktopComponent on mobile devices */
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
